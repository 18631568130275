<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import {
  Channel,
  DeliveryChannel,
  DeliveryChannelOption,
} from '/~/extensions/otp/composables/core/Otp'
import OtpFactory from '/~/extensions/otp/composables/core/OtpFactory'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseRadio from '/~/components/base/radio/base-radio.vue'
import { useAuth } from '/~/composables/auth'
import { useProvider } from '/~/composables/provider'
import AuthLayout from '/~/layouts/auth/layout-auth.vue'

const router = useRouter()
const { logout } = useAuth()

const iconsMap = {
  mobile: 'heroicons/solid/device-phone-mobile',
  email: 'heroicons/solid/envelope',
}

const otp = OtpFactory.restore('login')

const deliveryChannelOptions = computed(() => {
  return otp.deliveryChannels.map((m: DeliveryChannel) => ({
    icon: iconsMap[m.channel],
    label: 'Send a verification code to ' + m.name,
    value: m.channel,
    token: m.value,
  })) as DeliveryChannelOption[]
})

const selectedOption = ref(deliveryChannelOptions.value[0]?.value as Channel)
const loading = ref(false)

const { customerServiceNumber, generalEmail } = useProvider()

async function onRequest() {
  try {
    await otp.initiate(selectedOption.value)

    router.push({ name: 'verify-account' })
  } catch (err) {
    console.error(err)
  }
}

function onCancel() {
  loading.value = true
  logout()
}
</script>

<template>
  <auth-layout>
    <div
      class="flex h-full w-full items-center justify-center bg-eonx-neutral-50"
    >
      <div
        class="flex w-full max-w-[640px] flex-col justify-center rounded-xl bg-white p-8"
      >
        <div class="mx-auto rounded-full bg-primary-lightest p-5 text-primary">
          <base-icon :size="40" svg="heroicons/solid/shield-check" />
        </div>
        <h2 class="my-4 text-center text-2xl text-eonx-neutral-800">
          Verify your account
        </h2>
        <p class="mb-4 text-eonx-neutral-600">
          To login to your account, you will need to enter the code sent to your
          device.
        </p>
        <p class="mb-4 text-eonx-neutral-600">
          If you would like to have this delivered to a different mobile number,
          you'll need to contact our support team on
          <base-link :href="`tel:${customerServiceNumber}`">
            {{ customerServiceNumber }}
          </base-link>
          or
          <base-link :href="`mailto:${generalEmail}`">
            {{ generalEmail }}
          </base-link>
          .
        </p>
        <div class="my-8 flex flex-col gap-3">
          <template v-if="!deliveryChannelOptions.length">
            <base-loader />
          </template>
          <template v-else>
            <base-radio
              v-for="option in deliveryChannelOptions"
              :key="option.value"
              v-model="selectedOption"
              v-analytics:input="{
                page: '2FA code source',
                pageGroup: 'Login',
                label: `Change 2FA source to ${option.value}`,
              }"
              :value="option.value"
              direction="right"
              class="ml-0 h-16 w-full overflow-hidden !rounded-lg border px-4 py-[8px] hover:!bg-white hover:shadow-ring"
            >
              <div class="flex flex-row items-center">
                <base-icon :size="40" :svg="option.icon" class="text-primary" />

                <span
                  class="ml-4 whitespace-normal text-sm font-bold text-eonx-neutral-800"
                >
                  {{ option.label }}
                </span>
              </div>
            </base-radio>
          </template>
        </div>
        <div class="mt-8 grid grid-cols-2 gap-2">
          <base-button
            v-analytics:click="{
              page: '2FA code source',
              pageGroup: 'Login',
              cta: 'Cancel',
            }"
            :disabled="loading || otp.isInitiating"
            :loading="loading"
            look="outlined-color"
            @click="onCancel"
          >
            Cancel
          </base-button>
          <base-button
            v-analytics:click="{
              page: '2FA code source',
              pageGroup: 'Login',
              cta: 'Request code',
            }"
            :disabled="!deliveryChannelOptions.length || loading"
            :loading="otp.isInitiating && !loading"
            @click="onRequest"
          >
            Request code
          </base-button>
        </div>
      </div>
    </div>
  </auth-layout>
</template>
